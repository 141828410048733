<template>
  <!-- CheckboxButtonComponent -->
  <div
    v-for="(opt, index) in options"
    :key="index"
    class="inline-flex"
    :class="{
      'pointer-events-none': disabled || (value?.length === max && !value?.includes(opt.value))
    }"
  >
    <input
      class="peer absolute top-0 left-0 opacity-0"
      :class="inputClass"
      type="checkbox"
      :name="fieldName"
      :checked="value?.includes(opt.value)"
      :disabled="disabled || (value?.length === max && !value?.includes(opt.value))"
    />
    <label
      class="flex cursor-pointer items-center rounded-full bg-white px-12 text-xs font-medium leading-xs text-on-surface-elevation-2 border-1 border-solid border-[var(--stds-glob-color-gray60)] peer-checked:bg-blue50 peer-checked:border-blue300 peer-checked:text-brand-primary"
      :class="{
        'h-32': size === 'sm',
        'h-36': !size || size !== 'sm',
        [`${labelClass}`]: !!inputClass,
        'border-[var(--stds-sem-color-disabled-variant-3)] !text-[#BBBFC3]':
          disabled || (value?.length === max && !value?.includes(opt.value))
      }"
      @click="handleModelUpdate(opt.value)"
    >
      {{ $t(opt.label) }}
    </label>
  </div>

  <st-error-message :name="fieldName" :showError="showFieldError" />
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';

import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import type { CheckboxButtonsProps } from '@/types/common/form.type';

const props = withDefaults(defineProps<CheckboxButtonsProps>(), {
  size: 'md'
});

const { rules } = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [v: string[]];
}>();

const value = ref(props.modelValue);
const showFieldError = ref(false);

const setFieldValue = ref<(value: string[]) => void>();

const fieldName = computed<string>(() => props.name ?? '');

if (fieldName.value) {
  const { setValue, showError } = useValidation({
    fieldName: fieldName.value,
    rules,
    value
  });
  setFieldValue.value = setValue;

  watch(
    () => showError.value,
    (v: boolean) => {
      showFieldError.value = v;
    }
  );
}

watch(
  () => props.modelValue,
  (v?: string[]) => {
    value.value = v;
  }
);

const handleModelUpdate = (e: string) => {
  if (props.max && value.value) {
    if (value.value?.length >= props.max && !value.value?.includes(e)) {
      return;
    }
  }

  let result = [];
  if (value.value?.includes(e)) {
    result = value?.value.filter((v: string) => v !== e);
  } else {
    result = [...(value.value ?? []), e];
  }
  if (setFieldValue.value) {
    setFieldValue.value(result);
  }
  emit('update:modelValue', result);
};
</script>
